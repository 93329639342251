<template>
  <div id='fault_wrap'>
      <img src="../../assets/img/404.png" alt="">
  </div>
</template>

<script>
export default {
  name:'fault',
  components:{},
  props:{},
  data(){
    return {

    }
  },
  watch:{},
  computed:{},
  methods:{},
  created(){},
  mounted(){}
}
</script>
<style lang='scss' scoped>
  @import "./index.scss";
</style>